import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { format, utcToZonedTime } from 'date-fns-tz'

import { dayjs } from '../../../utils/dayjs/dayjs'
import { useAuth } from '../useAuth/useAuth'

import { getTimeZoneFromAuthState } from '@percent/workplace-giving/context/auth/authContextController/AuthContextController'

const getTimeZoneName = (timeZone: string) => {
  const zonedDate = utcToZonedTime(new Date(), timeZone)

  return format(zonedDate, 'zzz', { timeZone })
}

export const useDateTimeFmt = () => {
  const auth = useAuth()
  const timeZone = getTimeZoneFromAuthState(auth.state)
  const {
    i18n: { language: locale }
  } = useTranslation()

  const formatTime = useCallback(
    (d: Date) => {
      return `${dayjs(d)
        .tz(timeZone ?? undefined)
        .locale(locale)
        .format('LT')} ${getTimeZoneName(timeZone ?? 'UTC')}`
    },
    [timeZone, locale]
  )

  const formatDateWODay = useCallback(
    (d: Date | string) => {
      return dayjs(d)
        .tz(timeZone ?? undefined)
        .locale(locale)
        .format('LL')
    },
    [timeZone, locale]
  )
  const formatDate = useCallback(
    (d: Date) => {
      return dayjs(d)
        .tz(timeZone ?? undefined)
        .locale(locale)
        .format('LLLL')
        .replace(
          dayjs(d)
            .tz(timeZone ?? undefined)
            .locale(locale)
            .format('LT'),
          ''
        )
    },
    [timeZone, locale]
  )
  const formatDateTime = useCallback(
    (d: Date) => {
      return `${dayjs(d)
        .tz(timeZone ?? undefined)
        .format('LLLL')} ${getTimeZoneName(timeZone ?? 'UTC')}`
    },
    [timeZone]
  )

  const formatDurationDateTime = useCallback(
    (startDate: Date, endDate?: Date) => {
      if (!endDate) {
        return formatTime(startDate)
      }

      const startTime = dayjs(startDate)
        .tz(timeZone ?? undefined)
        .locale(locale)
        .format('LT')

      if (dayjs(startDate).isSame(endDate, 'day')) {
        const endTime = formatTime(endDate)

        return `${startTime} - ${endTime}`
      }

      return `${startTime} - ${dayjs(endDate)
        .tz(timeZone ?? undefined)
        .format('DD MMMM YYYY')}, ${formatTime(endDate)}`
    },
    [formatTime, locale, timeZone]
  )

  return {
    formatTime,
    formatDate,
    formatDateTime,
    formatDateWODay,
    formatDurationDateTime,
    getTimeZoneName
  }
}

export type DateTimeFmtMethods = ReturnType<typeof useDateTimeFmt>
