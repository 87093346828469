import { Box } from '@mui/material'

import { useColorTheme } from '@percent/workplace-giving/common/hooks/useColorTheme/useColorTheme'
import { getStyles } from './HeroImage.styles'

export function HeroImage({ image }: Readonly<{ image?: string | null }>) {
  const { theme } = useColorTheme()
  const Styles = getStyles(theme)

  return !image ? (
    <Box sx={Styles.BannerFallback} data-testid="description-fallback-banner-img" />
  ) : (
    <Box component="img" sx={Styles.Banner} data-testid="description-banner-img" src={image} alt="banner-img" />
  )
}
