/* eslint-disable no-console */
import * as Sentry from '@sentry/react'
import { useCallback, useEffect, useMemo } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom-v6'

import { LoggerContext } from '../LoggerContext/LoggerContext'
import { LoggerContextProps } from '../LoggerContext/LoggerContext.types'

import { environment } from '@percent/workplace-giving/config/config'

const useSentryLogger = ['sandbox', 'staging', 'preprod', 'production'].includes(process.env.NX_REACT_APP_ENV as string)

if (useSentryLogger) {
  Sentry.init({
    dsn: process.env.NX_SENTRY_DSN,
    integrations: [
      Sentry.captureConsoleIntegration({ levels: ['warn'] }),
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      })
    ],
    environment,
    autoSessionTracking: true
  })
}

export function LoggerContextController({ children }: LoggerContextProps) {
  const logError = useCallback((error: unknown) => {
    if (useSentryLogger) {
      Sentry.captureException(error)
    } else {
      console.log(error)
    }
  }, [])

  const logMessage = useCallback((message: string) => {
    if (useSentryLogger) {
      Sentry.captureMessage(message)
    } else {
      console.log(message)
    }
  }, [])

  const contextValue = useMemo(() => ({ logError, logMessage }), [logError, logMessage])

  return <LoggerContext.Provider value={contextValue}>{children}</LoggerContext.Provider>
}
