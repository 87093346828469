import { Axios } from 'axios'

export type PBPLinks = {
  current: string
  next?: string
  prev?: string
  first?: string
  last?: string
}

type RawError = {
  error: {
    reasons?: string[]
    code: string
    message: string
  }
}

export type RawValidResponse<T> = {
  data: T
  object: string
}

export type RawResponse<T> = RawValidResponse<T> | RawError

type ValidResponse<T> = { result: 'success'; status: number } & RawValidResponse<T>
type ErrorResponse = { result: 'error'; status: number } & RawError

export type GsResponse<T> = ValidResponse<T> | ErrorResponse

export type CoreListResponse<A> = { data: A[]; object: string; totalResults: number }

export type ListResponse<A> = CoreListResponse<A> & {
  pageSize: number
  _links: PBPLinks
}

export type QueryListFunction<
  TKey extends string,
  TArgs extends { [key: string]: any },
  TResponse extends ListResponse<unknown>
> = (_: Axios, __: [TKey, TArgs]) => Promise<TResponse>

export const isValidResponse = <T>(status: number, response: RawResponse<T>): response is RawValidResponse<T> =>
  status >= 200 && status < 300

export const toGsResponse = <T>(status: number, response: RawResponse<T>): GsResponse<T> =>
  isValidResponse(status, response)
    ? { result: 'success', status, ...response }
    : { result: 'error', status, ...response }

export const okResponse = <T>(response: GsResponse<T>): response is ValidResponse<T> => response.result === 'success'

export type SearchResponse<A> = ListResponse<A> & { isTotalExhaustive: boolean }
