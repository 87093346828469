import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { TitleAndStoryProps } from '../Description.types'

import * as Styles from './TitleAndStory.styles'
import { Icon, RichDescription, Text } from '@percent/lemonade'
import { useDateTimeFmt } from '@percent/workplace-giving/common/hooks/useDateTimeFmt/useDateTimeFmt'
import { formatShortOfflineOpportunityAddress } from '@percent/workplace-giving/utils/formatOfflineOpportunityAddress'
import { OpportunityLocationTypeDto } from '@percent/workplace-giving/api/opportunity/dto'

export function TitleAndStory({ name, story, location, startDate, itinerary }: Readonly<TitleAndStoryProps>) {
  const { t } = useTranslation()
  const { formatTime, formatDate } = useDateTimeFmt()

  const formatItineraryToHtml = (): string => {
    const itineraryItemsHTML: string[] = []

    if (itinerary) {
      itinerary
        .sort((a, b) => a.id - b.id)
        .forEach(item => {
          itineraryItemsHTML.push(`<dt>${item.title}</dt>${item.description ? `<dd>${item.description}</dd>` : ''}`)
        })
    }

    return itineraryItemsHTML.length
      ? `<h2>${t('workplace_giving.volunteering.itinerary')}</h2>
      <dl>${itineraryItemsHTML.join('')}</dl>
      `
      : ''
  }

  return (
    <>
      <Typography sx={Styles.Title}>{name}</Typography>

      {location?.type === OpportunityLocationTypeDto.Offline ? (
        <Box sx={Styles.EventLocationDetails}>
          {startDate ? (
            <>
              <Typography>{t('workplace_giving.wizard.dateAndTime')}</Typography>
              <Box>
                <Icon name="calendar" size="m" color="neutral300" />
                <Text as="p" size="small" fontWeight="medium">
                  {formatDate(new Date(startDate))}
                </Text>
              </Box>
              <Box>
                <Icon name="clock" size="m" color="neutral300" />
                <Text as="p" size="small" fontWeight="medium">
                  {formatTime(new Date(startDate))}
                </Text>
              </Box>
            </>
          ) : null}

          <Typography>{t('workplace_giving.wizard.location')}</Typography>
          <Box>
            <Icon name="pin" size="m" color="neutral300" />
            <Text as="p" size="small" fontWeight="medium">
              {formatShortOfflineOpportunityAddress({ location })}
            </Text>
          </Box>

          <Typography>{t('workplace_giving.volunteering.aboutThisOpportunity')}</Typography>
        </Box>
      ) : null}

      <RichDescription
        sanitizationConfig={{
          ADD_ATTR: ['target']
        }}
        content={`${story}${formatItineraryToHtml()}`}
      />
    </>
  )
}
