import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { TFunction } from 'i18next'
import duration from 'dayjs/plugin/duration'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import advanced from 'dayjs/plugin/advancedFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(advanced)
dayjs.extend(utc)
dayjs.extend(relativeTime)
dayjs.extend(duration)
dayjs.extend(timezone)
dayjs.extend(localizedFormat)

export { dayjs }

export const formatRelativeTime = (date: Date | string, t: TFunction, format: (_: Date) => string) => {
  const donationCreatedAt = dayjs(date)
  const now = dayjs()

  return now.diff(donationCreatedAt, 'minute') <= 5
    ? t('workplace_giving.time.just_now')
    : format(donationCreatedAt.toDate())
}

export const formatMinutesToReadable = (timeInMinutes: number, t: TFunction) => {
  if (timeInMinutes <= 0) {
    return `0${t('workplace_giving.timeFormatting.minutesAbbrev')}`
  }

  const durationTime = dayjs.duration(timeInMinutes, 'minutes')
  const days = durationTime.days() ? `${durationTime.days()}${t('workplace_giving.timeFormatting.daysAbbrev')}` : ''
  const hours = durationTime.hours() ? `${durationTime.hours()}${t('workplace_giving.timeFormatting.hoursAbbrev')}` : ''
  const minutes = durationTime.minutes()
    ? `${durationTime.minutes()}${t('workplace_giving.timeFormatting.minutesAbbrev')}`
    : ''

  return `${days}${(days && hours) || (days && minutes) ? ' ' : ''}${hours}${hours && minutes ? ' ' : ''}${minutes}`
}

export const formatMinutesToHours = (timeInMinutes: number, t: TFunction) => {
  if (timeInMinutes <= 0) {
    return `0${t('workplace_giving.timeFormatting.minutesAbbrev')}`
  }

  const durationTime = dayjs.duration(timeInMinutes, 'minutes')
  const minutesValue = durationTime.asMinutes()
  const hours = Math.floor(minutesValue / 60)
  const minutes =
    minutesValue % 60 ? `${durationTime.minutes()}${t('workplace_giving.timeFormatting.minutesAbbrev')}` : ''

  return `${hours ? `${hours}${t('workplace_giving.timeFormatting.hoursAbbrev')}` : ''}${
    hours && minutes ? ' ' : ''
  }${minutes}`
}

export const getDurationTime = (
  start: string,
  end: string,
  roundUpToUserFriendlyUnits?: boolean,
  minutesRoundUpValue?: number
) => {
  const durationTimeDayDiff = dayjs(end).diff(dayjs(start), 'day')
  const durationTimeHourDiff = dayjs(end).diff(dayjs(start), 'hour') - durationTimeDayDiff * 24
  const durationTimeMinuteDiff =
    dayjs(end).diff(dayjs(start), 'minute') - durationTimeHourDiff * 60 - durationTimeDayDiff * 24 * 60

  if (!roundUpToUserFriendlyUnits) {
    return {
      days: durationTimeDayDiff,
      hours: durationTimeHourDiff,
      minutes: durationTimeMinuteDiff
    }
  }

  const roundUpValue = minutesRoundUpValue || 5

  const roundedMinutes = Math.ceil(durationTimeMinuteDiff / roundUpValue) * roundUpValue
  const roundedHours = roundedMinutes === 60 ? durationTimeHourDiff + 1 : durationTimeHourDiff
  const roundedDays = roundedHours === 24 ? 1 : durationTimeDayDiff

  return {
    days: roundedDays,
    hours: roundedHours === 24 ? 0 : roundedHours,
    minutes: roundedMinutes === 60 ? 0 : roundedMinutes
  }
}
