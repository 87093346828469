/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
export const is12hourFormat = () => {
  return Boolean(
    new Intl.DateTimeFormat(undefined, {
      hour: 'numeric'
    }).resolvedOptions().hour12
  )
}

export const isValid12HourTime = (time: string) => {
  const match = time.match(/^(\d{1,2}):(\d{2})\s*(am|pm)?$/i)

  if (!match) return false

  const [_, hours, minutes] = match
  const hoursInt = parseInt(hours, 10)
  const minutesInt = parseInt(minutes, 10)

  return hoursInt >= 1 && hoursInt <= 12 && minutesInt >= 0 && minutesInt <= 59
}

export const isValid24HourTime = (time: string) => {
  const match = time.match(/^(\d{1,2}):(\d{2})$/)

  if (!match) return false

  const [_, hours, minutes] = match
  const hoursInt = parseInt(hours, 10)
  const minutesInt = parseInt(minutes, 10)

  return hoursInt >= 0 && hoursInt <= 23 && minutesInt >= 0 && minutesInt <= 59
}

export const convertTo12HourFormat = (time: string) => {
  if (!time) return ''

  const [hoursStr, minutes] = time.split(':')
  const hours = parseInt(hoursStr, 10)

  if (isNaN(hours) || isNaN(parseInt(minutes, 10))) return time

  const isPM = hours >= 12
  const formattedHours = hours % 12 || 12
  const amPm = isPM ? 'pm' : 'am'

  const regex = /\b(?:am|pm)\b|(?:am|pm)/i
  const alreadyHasAmPm = regex.test(time)

  return `${formattedHours}:${minutes.padStart(2, '0')}${alreadyHasAmPm ? '' : amPm}`
}

export const convertTo24HourFormat = (time: string) => {
  if (!time) return ''

  const match = time.match(/^(\d{1,2}):(\d{2})(am|pm)$/i)

  if (!match) return time

  let [_, hours, minutes, amPm] = match
  let hoursValue = parseInt(hours, 10)

  amPm = amPm.toLowerCase()

  if (amPm.toLowerCase() === 'pm' && hoursValue !== 12) {
    hoursValue += 12
  } else if (amPm.toLowerCase() === 'am' && hoursValue === 12) {
    hoursValue = 0
  }

  hours = hoursValue.toString().padStart(2, '0')
  minutes = minutes.toString().padStart(2, '0')

  return `${hours}:${minutes}`
}

export const generateTimeOptions = (formatTo12Hrs: boolean, interval = 15) => {
  const hours = Array.from({ length: 24 }, (_, h) => h)
  const minutes = Array.from({ length: 60 / interval }, (_, m) => m * interval)

  return hours.flatMap(h =>
    minutes.map(m => {
      const hour = `${h}`.padStart(2, '0')
      const minute = `${m}`.padStart(2, '0')

      if (formatTo12Hrs) {
        const period = h >= 12 ? 'pm' : 'am'
        const formattedHour = h % 12 === 0 ? 12 : h % 12
        const displayHour = `${formattedHour}`.padStart(2, '0')

        return `${displayHour}:${minute}${period}`
      }

      return `${hour}:${minute}`
    })
  )
}

export const formatTime = (time: string, formatTo12Hrs: boolean) => {
  let formattedTime = time.trim()

  const compactAmPmMatch = formattedTime.match(/^(\d{1,2})(\d{2})(am|pm)$/i)

  if (compactAmPmMatch) {
    const [, hours, minutes, amPm] = compactAmPmMatch
    formattedTime = `${hours}:${minutes}${amPm}`
  }

  const match = formattedTime.match(/^(\d{1,2})(?::(\d{1,2}))?\s*(am|pm)?$/i)

  if (!match) {
    const notSeparatedTime = formattedTime.match(/^(\d{3,4})$/)

    if (notSeparatedTime) {
      const compactTime = notSeparatedTime[1]
      const hours = compactTime.length === 3 ? `0${compactTime[0]}` : compactTime.slice(0, 2)
      const minutes = compactTime.length === 3 ? compactTime.slice(1) : compactTime.slice(2)
      formattedTime = `${hours}:${minutes}`
    } else {
      return formattedTime
    }
  }

  let [_, hours = '', minutes = '', amPm] = formattedTime.match(/^(\d{1,2})(?::(\d{1,2}))?\s*(am|pm)?$/i) || []

  if (minutes === undefined || minutes === '') {
    minutes = '00'
  } else if (minutes.length === 1) {
    minutes += '0'
  }

  if (hours.length === 1) {
    hours = `0${hours}`
  }

  if (formatTo12Hrs) {
    return convertTo12HourFormat(`${hours}:${minutes}${amPm || ''}`)
  }

  if (amPm) {
    amPm = amPm.toLowerCase()
    let hoursInt = parseInt(hours, 10)

    if (amPm === 'pm' && hoursInt !== 12) {
      hoursInt += 12
    } else if (amPm === 'am' && hoursInt === 12) {
      hoursInt = 0
    }

    hours = hoursInt.toString().padStart(2, '0')
  }

  return `${hours}:${minutes}`
}
