export enum OpportunityStatus {
  Published = 'published',
  Closed = 'closed',
  Cancelled = 'cancelled'
}

export enum OpportunityStatusDto {
  Active = 'active',
  Inactive = 'inactive'
}

export enum OpportunityType {
  Event = 'event',
  Project = 'project',
  EventSeries = 'event_series',
  EventSeriesSession = 'event_series_session'
}

export enum OpportunityTimeTracking {
  Automatic = 'automatic',
  Manual = 'manual'
}

export enum ExternalParticipationStatus {
  AwaitingApproval = 'awaiting_approval',
  Registered = 'registered',
  Rejected = 'rejected',
  Confirmed = 'confirmed',
  NotParticipating = 'not_participating'
}

export enum OpportunityLocationTypeDto {
  Virtual = 'virtual',
  Offline = 'in-person'
}

export type OpportunityOnlineLocationDto = Readonly<{
  type: OpportunityLocationTypeDto.Virtual
  link?: string
}>

export type OpportunityInPersonLocationDto = Readonly<{
  type: OpportunityLocationTypeDto.Offline
  country: string
  city: string
  zipCode: string
  addressLineOne: string
  addressLineTwo?: string
  locationName?: string
  locationDisplayName?: string
  long: number
  lat: number
}>

export type OpportunityLocationDto = OpportunityOnlineLocationDto | OpportunityInPersonLocationDto

export type OpportunitySkillDto = Readonly<{
  id: string
  name: string
}>

export type OpportunityActivityDto = OpportunitySkillDto

export type OpportunityExternalDataDto = Readonly<{
  provider: string
}>

export type OpportunityItineraryDto = Readonly<{
  id: number
  durationMinutes: number
  title: string
  description?: string | null
}>

export type OpportunityDto = Readonly<{
  id: string
  name: string
  description: string | null
  parentId: string | null
  image: string | null
  startDate: string | null
  endDate: string | null
  location: OpportunityLocationDto
  type: OpportunityType
  createdBy: string | null
  skills: OpportunitySkillDto[]
  activities: OpportunityActivityDto[]
  spots: number | null
  itinerary: OpportunityItineraryDto | null
  external: OpportunityExternalDataDto | null
  timeTracking: OpportunityTimeTracking
}>

export type OpportunityDetailDto = Readonly<{
  id: string
  name: string
  description: string | null
  parentId: string | null
  image: string | null
  type: OpportunityType
  status: OpportunityStatus
  location: OpportunityLocationDto
  startDate: string | null
  endDate: string | null
  organisation: {
    id: string
    name: string
    logo: string | null
    countryCode: string
  } | null
  organiser: {
    id: string
    fullName: string
    image: string | null
    email: string
  } | null
  skills: {
    id: string
    name: string
  }[]
  spots: number | null
  participantsCount: number
  activities: {
    id: string
    name: string
  }[]
  itinerary:
    | {
        id: number
        durationMinutes: number
        title: string
        description: string | null
      }[]
    | null
  participation?: {
    status: 'participating' | 'not_participating' | 'awaiting_approval'
  } | null
  timeTracking: OpportunityTimeTracking
  external: {
    provider: string
  } | null
  cancelledBy: string | null
  notificationsEnabled: boolean
}>

export type OpportunitySelfParticipationDto = Readonly<{
  participationStatus: ExternalParticipationStatus
  registrationDate: string | null
  opportunity: {
    id: string
    name: string
    type: OpportunityType
    status: 'active' | 'closed'
    parent: { id: string; name: string } | null
    location: OpportunityLocationDto
    organisation: {
      id: string
      name: string
      logo?: string
    } | null
    organiser: {
      id: string
      fullName: string
      image: string | null
      email: string
    } | null
    startDate: string
    endDate?: string
    spots?: number
    participantsCount: number
    activities: { id: string; name: string }[]
    isCreatedByMe: boolean
    timeTracking: OpportunityTimeTracking
    external?: { provider: string }
  }
}>

export enum VolunteeringTimeLogStatus {
  AwaitingApproval = 'awaiting_approval',
  Approved = 'approved',
  Deleted = 'deleted'
}

export enum VolunteeringTimeLogType {
  OnPlatform = 'on_platform',
  OffPlatform = 'off_platform'
}

export type TimeLogDetailDto = Readonly<{
  id: string
  minutes: number
  date: string
  status: VolunteeringTimeLogStatus
  type: VolunteeringTimeLogType
  activities: { id: string; name: string }[]
  skills: { id: string; name: string }[]
  opportunity: {
    id: string
    name: string
    type: OpportunityType
  } | null
  organisation: {
    id: string
    name: string
    logo: string | null
  } | null
  createdAt: string
  updatedAt: string
}>

export type ChildSessionEvent = Readonly<{
  id: string
  name: string
  startDate: Date
  endDate: Date
  location: OpportunityLocationDto
  participantSpots?: number
}>
