import { OpportunityLocationDto, OpportunityLocationTypeDto } from '../api/opportunity/dto'

export const formatFullOfflineOpportunityAddress = (opportunity: { location: OpportunityLocationDto }) => {
  if (opportunity.location.type !== OpportunityLocationTypeDto.Offline) return undefined

  const cleanDisplayName = opportunity.location.locationDisplayName
    ? opportunity.location.locationDisplayName
        .replace(opportunity.location.zipCode, '')
        .replace(opportunity.location.country, '')
        .trim()
    : undefined

  const addressLine = cleanDisplayName?.length
    ? cleanDisplayName
    : [
        opportunity.location.locationName,
        opportunity.location.addressLineOne,
        opportunity.location.addressLineTwo,
        opportunity.location.city
      ]
        .filter(arg => arg?.trim()?.length)
        .join(', ')

  return `${addressLine}, ${opportunity.location.zipCode} ${opportunity.location.country}`
}

export const formatShortOfflineOpportunityAddress = (opportunity: { location: OpportunityLocationDto }) => {
  if (opportunity.location.type !== OpportunityLocationTypeDto.Offline) return undefined

  const cleanDisplayName = opportunity.location.locationDisplayName
    ? opportunity.location.locationDisplayName
        .replace(opportunity.location.zipCode, '')
        .replace(opportunity.location.country, '')
        .trim()
    : undefined

  const maybeCity = opportunity.location.city?.trim()?.length ? opportunity.location.city : undefined
  const maybeDisplayName = cleanDisplayName?.length ? cleanDisplayName : undefined

  return [maybeCity ?? maybeDisplayName, opportunity.location.country].filter(Boolean).join(', ')
}
